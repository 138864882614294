.step-list {
    list-style-type: none;
    padding-left:0;
    li{
        height:40px;
        width:40px;
        padding:5px;
        border:1px solid #2e2e2e;
        border-radius: 50%;

        span{
            display: block;
            background:#2e2e2e;
            height:28px;
            width:28px;
            line-height: 28px;
            text-align: center;
            border-radius: 50%;
            margin:0 auto;
            color:#fff;
        }
    }
}

.search-list, .List {
    overflow-x: hidden !important;
    .column{
        flex-direction: column !important;
    }
}

.overflow-visible .search-list, .overflow-visible .List {
    overflow-x: visible !important;
}

.table-header {
    line-height: 44px;
    border-bottom: 1px solid $bt-input-border;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-weight: bold;
}

.dark {
    .table-header {
        border-bottom: 1px solid $bt-input-border-dark;

    }
}
