@import "../variables";

$switch-height: 28px;
$switch-width: 55px;
.rc-switch:disabled {
  opacity: 0.5;
  .rc-switch-checked {
    opacity: 0.5;
  }
}
.dark {
  .rc-switch {
    &:after {
      background: $panel-heading-bg-dark;
    }
    background-color: $bt-switch-dark;
    border: 1px solid $bt-switch-dark;
  }
  .rc-switch-checked {
    border: 1px solid $bt-switch-active-dark;
    background-color: $bt-switch-active-dark;
  }
}
.switch-checked,.switch-unchecked, {
  position: absolute;
  top: 0;
  height: 28px;
  line-height: 28px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  letter-spacing: 1.2px;
}
.switch-unchecked {
  right: 6px;
  color: white;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}

.switch-checked {
  left: 14px;
  color: white;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;

}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rc-switch {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  box-sizing: border-box;
  width: 55px;
  height: 28px;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: none !important;
  background-color: #999;
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-switch-inner {
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 24px;
}
.rc-switch:after {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 3px;
  top: 4px;
  border-radius: 50% 50%;
  background-color: #ffffff;
  content: " ";
  cursor: pointer;
  //box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: rcSwitchOff;
  animation-name: rcSwitchOff;
}
.rc-switch:hover:after {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-animation-name: rcSwitchOn;
  animation-name: rcSwitchOn;
}
.rc-switch:focus {
  box-shadow: 0 0 0 2px #d5f1fd;
  outline: none;
}
.rc-switch-checked {
  border: 1px solid $bt-switch-active;
  background-color: $bt-switch-active;
}
.rc-switch-checked .rc-switch-inner {
  left: 6px;
}
.rc-switch-checked:after {
  left: $switch-width/1.7;
}
.rc-switch-disabled {
  cursor: no-drop;
  background: #ccc;
  border-color: #ccc;
}
.rc-switch-disabled:after {
  background: #9e9e9e;
  -webkit-animation-name: none;
  animation-name: none;
  cursor: no-drop;
}
.rc-switch-disabled:hover:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation-name: none;
  animation-name: none;
}
.rc-switch-label {
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
@-webkit-keyframes rcSwitchOn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes rcSwitchOn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@-webkit-keyframes rcSwitchOff {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes rcSwitchOff {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.widget-mode {
  .rc-switch {
    cursor: default;
    opacity: 1;
    @extend .rc-switch;
    &.rc-switch-checked {
      @extend .rc-switch-checked;
    }
  }
}
