.react-select{

  &--is-disabled {
    opacity: 0.5;
  }
  input[type="text"] {
    border: none !important;
  }
  &__indicator-separator {
    display: none;
  }
  &__control {
    background: $input-bg-dark;
    height: $input-height !important;
    border-radius: $border-radius-default !important;
    &--is-focused {
      border-color: $bt-brand-primary !important;
      box-shadow: 0 0 0 1px $bt-brand-primary !important;
    }
  }
  &__value-container {
    padding: 0px 8px;
  }
  &__option {
    color: $body-text !important;
    background: white !important;
    &:hover {
      background: $bt-brand-primary !important;
      color: white !important;
    }
  }
}
.dark {
  .react-select {
    &--is-disabled {
      opacity: 0.25;
    }
    input[type="text"] {
      color: white !important;
      border: none !important;
    }
    &__control {
      background: $input-bg-dark;
      border: none;
      box-shadow: none;
      &--is-focused {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
    &__single-value {
      color: $input-color-dark;
    }
    &__menu {
      background: $dark-bg-0;
      color: $input-color-dark;
    }
    &__option {
      color: white !important;;
      background: $dark-bg-0 !important;
      &:hover {
        background: darken($dark-bg-0,2) !important;
      }
    }
  }
}
.select-sm {
  .react-select {
    &__control {
      border: none;
      height: 24px !important;
      min-height: 24px !important;
    }
    &__value-container {
      margin-top: -7px;
    }
    &__indicator {
      margin-top: -6px;
    }
    &__indicator-separator {
      display: none;
    }
  }
}
.react-select__option {
  padding: 8px 12px;
}
